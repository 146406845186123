{
  "id": "grapevine",
  "name": "Gramene Grapevine",
  "version": "v4",
  "ga": "G-ZTEQBFCRXZ",
  "description": "Comparative plant genomics focused on grapevine varieties",
  "url": "https://vitis.gramene.org",
  "ensemblURL": "https://vitis-ensembl.gramene.org",
  "ensemblSite": "https://vitis-ensembl.gramene.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-87",
  "grameneData": "https://data.gramene.org/grapevine_v4",
  "curation": {
    "url": "http://curate.gramene.org/grapevine_v4?since=71275&gene=",
    "taxa": {
      "29760020": 1,
      "29760019": 1
    }
  },
  "targetTaxonId": 29760020,
  "portals": ['browser','blast','pansites','curated'],
  "portals2": ['pansites','browser','reactome','climtools','atlas','trackhub'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": true,
  "showFeed": true,
  "showTweets": true,
  "date": "2024-01-13",
  "slides": [],
  details: {
    sequences: true,
    VEP: false,
    location: true,
    expression: true,
    homology: true,
    pathways: true,
    pubs: true,
    xrefs: true
  },
  "panSite" : {
    "sorghum_bicolor" : {
      "url": "https://sorghumbase.org/genes?idList=",
      "name": "SorghumBase",
      "svg": "./static/images/sorghum_logo.svg"
    },
    oryza_sativa : {
      url: "https://oryza.gramene.org?idList=",
      name: "Gramene Oryza",
      svg: "./static/images/oryza_logo.svg"
    },
    zea_maysb73 : {
      url: "https://maize-pangenome.gramene.org?idList=",
      name: "Gramene Maize",
      svg: "./static/images/maize_logo.svg"
    }
  }

}
