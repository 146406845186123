var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import remarkGfm from 'remark-gfm';
import { Remark } from 'react-remark';
import remarkHeading from 'remark-heading-id';
import imgLinks from '@pondorasti/remark-img-links';
import { Alert, Table } from 'react-bootstrap';
import '../css/mdview.css';

var _default = function (_Component) {
  _inherits(_default, _Component);

  function _default(props) {
    _classCallCheck(this, _default);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.state = {};
    return _this;
  }

  _default.prototype.componentDidMount = function componentDidMount() {
    var _this2 = this;

    var url = 'https://api.github.com/repos/' + this.props.org + '/' + this.props.repo + '/contents/' + this.props.path;
    fetch(url).then(function (response) {
      return response.json();
    }).then(function (data) {
      var alerts = data.filter(function (f) {
        return f.name === _this2.props.site + '.md' || f.name === "APB.md";
      });
      if (alerts.length === 1) {
        fetch(alerts[0].download_url).then(function (response) {
          return response.text();
        }).then(function (content) {
          _this2.setState({ alertMD: content });
        });
      }
    });
  };

  _default.prototype.render = function render() {
    if (!this.state.alertMD) {
      return null;
    }
    return React.createElement(
      Alert,
      { variant: 'danger' },
      React.createElement(
        Remark,
        {
          remarkPlugins: [remarkGfm, remarkHeading, [imgLinks, { absolutePath: 'https://github.com/' + this.props.org + '/' + this.props.repo + '/raw/main/' + this.props.path + '/' }]],
          rehypeReactOptions: {
            components: {
              table: function table(props) {
                return React.createElement(Table, _extends({ size: 'sm', striped: true, bordered: true, hover: true }, props));
              }
            }
          }
        },
        this.state.alertMD
      )
    );
  };

  return _default;
}(Component);

export { _default as default };